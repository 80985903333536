<template>
  <div class="facial-features q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['facialSpotLocations'])">
      <face-zone-multi-select
        title="label.facialHyperpigmentationSpotLocations"
        :value="facialSpotLocations"
        :options="$options.spotLocationsOptions"
        :gender="gender"
        hint="label.selectAllRegionsThatApply"
        field-name="pigmentation"
        @input="onFieldChange('facialSpotLocations', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import FaceZoneMultiSelect from '@/modules/questionnaire/new-design-components/questions/face-zone-input/FaceZoneMultiSelect';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';
import { SPOT_LOCATIONS_OPTIONS } from '@/modules/questionnaire/constants/steps/skinPigmentation';

export default {
  name: 'FaceTemplate',
  components: { QuestionWithErrorWrap, FaceZoneMultiSelect },
  mixins: [stepTemplateMixin],
  spotLocationsOptions: SPOT_LOCATIONS_OPTIONS,
  props: {
    facialSpotLocations: {
      type: Array,
      default: () => []
    },
    gender: {
      type: String,
      required: true
    }
  }
};
</script>
